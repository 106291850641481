import React from "react";
import PropTypes from "prop-types";

class YouTubeVideo extends React.PureComponent {
  static propTypes = {
    id: PropTypes.string.isRequired,
  };

  componentDidMount = () => {
    const { id } = this.props;    
    if (!id) return;

    if (!window.YT) {
      const tag = document.createElement("script");
      tag.src = "https://www.youtube.com/iframe_api";
      window.onYouTubeIframeAPIReady = this.loadVideo;

      const firstScriptTag = document.getElementsByTagName("script")[0];
      firstScriptTag.parentNode.insertBefore(tag, firstScriptTag);
    } else {
      this.loadVideo();
    }
  };

  loadVideo = () => {
    const { id } = this.props;

    this.player = new window.YT.Player("player", {
      height: "390",
      width: "640",
      videoId: id,
      playerVars: {
        controls: 0,
        disablekb: 1,
        enablejsapi: 1,
        fs: 0,
        modestbranding: 1,
        playsinline: 1,
        iv_load_policy: 3,
      },
      events: {
        onReady: this.onPlayerReady,
        onStateChange: this.onPlayerStateChange,
      },
    });
  };

  onPlayerReady = (event) => {
    event.target.stopVideo();
  };

  onPlayerStateChange = (event) => {
    if (
      event.data == window.YT.PlayerState.ENDED ||
      event.data == window.YT.PlayerState.PAUSED
    ) {
      event.target.stopVideo();
    }
  };

  render = () => {
    const { id } = this.props;
    return (
      <>
        {id && (
          <div className="item video">
            <div className="video-player">              
              <div id="player"></div>
            </div>
          </div>
        )}
      </>
    );
  };
}

export default YouTubeVideo;
