import "./NotFound.css";

const WF_LINK = "https://www.winfluenced.com/";

function wfRedirect() {
    setTimeout(() => {
        window.location.replace(WF_LINK)
    }, 3000)
}

export default function NotFound() {
    return (
        <div className="notfound">
            <div className="text-center">
                <h1>404</h1>
                <p> <span className="text-danger">Opps!</span> Page not found.</p>
                <p>
                    <br/>
                    <a href={WF_LINK}>Go Home</a>
                </p>
            </div>

            { wfRedirect() }
        </div>
    );
}