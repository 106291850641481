export default function CollectionDetailItem(props) {
  const { item, innerRef } = props;
  const imageUrl = (item.imageUrl != "" ? item.imageUrl : null) ?? "https://static-cdn.winfluenced.com/images/noimage-placeholder.png";

  return (
    <div className="item" ref={innerRef}>
      <a href={item.affiliateUrl} target={"_blank"}>
        <div className="image">
          <img src={imageUrl} alt={item.title} />
        </div>
        <div className="subtitle">{item.brandName}</div>
        <div className="title">{item.title}</div>
        <div className="price">
          {item.price && <span>{item.price} TL</span>}
          {item.listPrice && <samp>{item.listPrice} TL</samp>}
        </div>
      </a>
    </div>
  );
}