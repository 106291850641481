import { Routes, Route } from 'react-router-dom';

import Collection from "./pages/Collection";
import CollectionDetail from "./pages/CollectionDetail";
import NotFound from "./NotFound";

function App() {
  return (
    <Routes>
      <Route path='/' element={<Home />} />      
      <Route path='/:domain/:slug' element={<Collection />} />
      <Route path='/:domain/:slug/:id' element={<CollectionDetail />} />
      <Route path='*' element={<NotFound />} />
    </Routes>
  );
}

function Home() {
  window.location.replace('https://www.winfluenced.com/');
  return null;
}

export default App;