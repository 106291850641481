const BASE_URL = "https://mobileapi-live.btrck.com/1.5";
const _BASE_URL = "https://localhost:44395";

export const getCollections = async (data) => {
    try {
        const response = await fetch(`${BASE_URL}/api/shop/Collection`,
            {
                method: "POST",
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(data)
            });

        return response.json();

    } catch {
        throw new Error("could not fetch the collections");
    }
};

export const getCollectionDetails = async (data) => {
    try {
        const response = await fetch(`${BASE_URL}/api/shop/Collection`,
            {
                method: "POST",
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(data)
            });

        return response.json();

    } catch {
        throw new Error("could not fetch the collections");
    }
};