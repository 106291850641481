import { Link } from "react-router-dom";

function CollectionItem(props) {
  const { item } = props;
  const imageUrl =
    (item.imageUrl != "" ? item.imageUrl : null) ??
    "https://static-cdn.winfluenced.com/images/noimage-placeholder.png";

  return (
    <>
      <div className="item">
        <Link to={`${item.slug}`}>
          <div className="image">
            <img src={imageUrl} alt={item.title} />
          </div>
          <div className="subtitle">{item.brandName}</div>
          <div className="title">{item.title}</div>
          <div className="price">
            <small>{item.linkCount} link</small>
          </div>
        </Link>
      </div>
    </>
  );
}

export default CollectionItem;
