import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { getCollections } from "../service";

import Header from "../components/Header";
import WithParams from "../components/WithParams";
import CollectionItem from "./CollectionItem";
import Spinner from "../Spinner";

function Collection(props) {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState({});
  const [items, setItems] = useState([]);
  const [error, setError] = useState("");

  useEffect(() => {
    setLoading(true);
    const { slug, domain } = props.params;
    const hostName = domain === "explore" ? "winfluenced.com" : domain;

    const postData = {
      requestType: 0,
      host: hostName,
      slug: slug,
      PageSize: 10,
      PageNumber: 1,
    };

    getCollections(postData).then(
      (result) => {
        if (result.statusCode === 400) {
          navigate("/notfound");
        }

        setData(result);
        setItems(result.items);
        setLoading(false);
      },
      (error) => {
        setLoading(false);
        setError(error);
      }
    );
  }, []);

  return (
    <>
      {!loading && <Header data={data} />}
      <div className="eproduct flex-container" id="eproduct">
        {items.map((item, index) => (
          <CollectionItem key={index} item={item} />
        ))}
      </div>
      {loading && <Spinner />}
      <div>{error && "Beklenmedik bir hata oluştu"}</div>
    </>
  );
}

export default WithParams(Collection);
