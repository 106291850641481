import { useEffect, useState } from "react";
import axios from "axios";

export default function useCollectionItemSearch(pageNumber, hostName, slug) {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const [data, setData] = useState({});
  const [items, setItems] = useState([]);
  const [hasMore, setHasMore] = useState(false);

  useEffect(() => {
    setLoading(true);
    setError(false);
    let cancel;

    const postdata = {
      "RequestType": 1, 
      "host": hostName,
      "slug": slug,
      "PageSize": 10,
      "PageNumber" : pageNumber
    };

    axios({
      method: "POST",
      url: "https://mobileapi-live.btrck.com/1.5/api/shop/Collection",
      data: postdata,
      cancelToken: new axios.CancelToken((c) => (cancel = c)),
    })
      .then((res) => {

        setItems((prevItems) => {
          return [
            ...new Set([...prevItems, ...res.data.items]),
          ];
        });

        setData(res.data);
        setHasMore(res.data.items.length > 0);
        setLoading(false);
      })
      .catch((e) => {
        if (axios.isCancel(e)) return;
        setError(true);
      });
    return () => cancel();
  }, [pageNumber]);

  return { loading, error, data, items, hasMore };
}