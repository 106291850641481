import { Link } from 'react-router-dom';

function Header(props) {
  const { avatarUrl, title, subTitle, brandUrl, brandName, brandImageUrl, userName, slug } = props.data;
  const pathName = props.pathName;
  const link = props.link;

  return (
    <>
      <div className="eheader">
        <div className="econtainer">
          <div className="header">
            <div className="logo">
              <a href={brandUrl}>
                <img src={brandImageUrl} alt="logo" />
              </a>
            </div>
            <div className="collection">
              {link ? <Link to={`/${pathName}/${slug}`}>{userName}</Link> : <div>{userName}</div>}
            </div>
          </div>
        </div>
        <div className="banner">
          <img src={avatarUrl} alt="avatar" />
          <span>{title}</span>
          <samp>{subTitle}</samp>
        </div>
      </div>
    </>
  );
}

export default Header;