import React, { useState, useRef, useCallback, useEffect } from "react";
import { useNavigate } from "react-router-dom";

import Header from "../components/Header";
import WithParams from "../components/WithParams";
import useCollectionItemSearch from "../useCollectionItemSearch";
import YouTubeVideo from "../YouTubeVideo";
import CollectionDetailItem from "./CollectionDetailItem";

import Spinner from "../Spinner";

function CollectionDetail(props) {
  const navigate = useNavigate();

  const { id, domain } = props.params;
  const hostName = domain === "explore" ? "winfluenced.com" : domain;

  const [pageNumber, setPageNumber] = useState(1);
  const { data, items, hasMore, loading, error } = useCollectionItemSearch(
    pageNumber,
    hostName,
    id
  );

  const observer = useRef();
  const lastItemElementRef = useCallback(
    (node) => {
      if (loading) return;
      if (observer.current) observer.current.disconnect();
      observer.current = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting && hasMore) {
          setPageNumber((prevPageNumber) => prevPageNumber + 1);
        }
      });
      if (node) observer.current.observe(node);
    },
    [loading, hasMore]
  );

  if (data.statusCode === 400) {
    navigate("/notfound");
  }

  return (
    <>
      {!loading && <Header data={data} link={true} pathName={domain} />}

      <div className="eproduct flex-container" id="eproduct">
        {data.videoUrl && <YouTubeVideo id={data.videoUrl} />}

        {items.map((item, index) => {
          return items.length === index + 1 ? (
            <CollectionDetailItem
              key={index}
              item={item}
              innerRef={lastItemElementRef}
            />
          ) : (
            <CollectionDetailItem key={index} item={item} />
          );
        })}
      </div>
      {loading && <Spinner />}
      <div>{error && "Beklenmedik bir hata oluştu"}</div>
    </>
  );
}

export default WithParams(CollectionDetail);
